import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button, Link } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`block`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  resume: {
    textDecoration: 'none',
    color: '#FFFFFF',
    ':visited': {
      color: '#FFFFFF',
    }
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)
  const keywords = [
    'ola',
    'practo',
    'walmart',
    'esm-polyfills',
    'esm',
    'grape',
    'buzy',
    'commonjs',
    'require',
    'architect',
    'aditya subramanyam',
    'aditya katakam ediga',
    'polyfills',
    'rust'
  ];
  return (
    <Layout {...props}>
      <Seo title='Aditya Subramanyam' keywords={keywords}/>
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} sx={styles.button}>
              <Link sx={styles.resume} href={`/resume.pdf`} target="_blank" rel="noreferrer">
                Download my resume
              </Link>
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  Hi, I am Aditya Subramanyam
                </Text>
                <Text variant='p'>
                I am a coder by profession, and I specialize in JavaScript. I have architected and built JavaScript apps from scratch that scale at Practo, Ola, and Walmart.
                </Text>
                <Text variant='p'>
                I take pride in my ability to solve difficult technical problems and create software that stands the test of time. My commitment to excellence and attention to detail has earned me a reputation as a reliable and knowledgeable software engineer.
                </Text>
                <Text variant='p'>
                I am always eager to learn new technologies and expand my skills. I stay up to date on the latest developments in the industry, and I am always looking for new and innovative ways to solve problems.
                </Text>
                <Text variant='p'>
                Apart from work, I dabble in Raspberry Pi projects and stock markets. I have also built some open source projects, which you can find listed here.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='FOSS projects'>
                <Card variant='paper'>
                <Text variant='p'>
                  <ul style={{listStyle: 'decimal'}}>
                    <li><Link href="https://github.com/subbu963/esm-polyfills">esm-polyfills</Link>: CommonJS polyfills for ESM like __dirname, __filename, etc.</li>
                    <li><Link href="https://github.com/subbu963/grape">grape</Link>: It's a React-ish library for building UI. Underneath, it uses virtual DOM to diff. It has an API similar to React and supports server-side rendering. It's more of an educational project as of now.</li>
                    <li><Link href="https://github.com/subbu963/buzy">buzy</Link>: Promise based async queue manager for node and browser.</li>
                  </ul>
                </Text>
                </Card>
              </Section>
              <Divider />
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery1 {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1300
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
